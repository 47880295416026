import api from "../../api/api";

class DistiplineSettingsService {
    async getSettingsAgeGroup() {
        return await api().get('settings/age_group')
    }

    async getSettingsDistiplines(id) {
        return await api().get(`settings/discipline/${id}`)
    }

    async getSettingsCouch(id) {
        return await api().get(`settings/programm/age_group_discipline_coach/${id}`)
    }

    async setSettingsCouch(payload) {
        return await api().post(`settings/programm/age_group_discipline_coach/save`, payload)
    }

    async getFactors(id) {
        return await api().get(`settings/programm/factors/${id}`)
    }

    async getFactorsCoff(id) {
        return await api().get(`settings/programm/basemark/${id}`)
    }

    async setReserv(age_group_id, discipline_id, draw_variant, discipline_stage_id) {
        return await api().get(`settings/programm/draw/select/${draw_variant}/${age_group_id}/${discipline_id}/${discipline_stage_id}`)
    }

    async downloadLinkReserv(age_group_id, discipline_id, discipline_stage_id) {
        return await api().get(`settings/programm/draw/download/${age_group_id}/${discipline_id}/${discipline_stage_id}`, {responseType: 'blob'})
    }

    async createReserv(age_group_id, discipline_id, discipline_stage_id) {
        return await api().get(`settings/programm/draw/create/${age_group_id}/${discipline_id}/${discipline_stage_id}`)
    }

    async setReservFigure(age_group_id, draw_variant) {
        return await api().get(`settings/figure/draw/select/${draw_variant}/${age_group_id}`)
    }

    async downloadLinkReservFigure(age_group_id) {
        return await api().get(`settings/figure/draw/download/${age_group_id}`, {responseType: 'blob'})
    }

    async createReservFigure(age_group_id) {
        return await api().get(`settings/figure/draw/create/${age_group_id}`)
    }

    async getBrigade(id) {
        return await api().get(`settings/figure/brigade/${id}`)
    }

    async setBrigadeFigure(id, payload) {
        return await api().post(`settings/figure/brigade/save/${id}`, payload)
    }

    async getTable(id) {
        return await api().get(`settings/figure/start/${id}`)
    }

    async getFigure() {
        return await api().get(`settings/figure`)
    }

    async setFigure(id_figure, id) {
        return await api().get(`settings/figure/start/save_figure/${id_figure}/${id}`)
    }

    async setSaveFigure(payload) {
        return await api().post(`settings/figure/start/save`, payload)
    }

    async getListRes(payload) {
        return await api().get(`settings/program/draw/applications/${payload?.age_group_id}/${payload?.discipline_id}/${payload?.discipline_stage_id}`)
    }

    async getListResFig(payload) {
        return await api().get(`settings/figure/draw/users/${payload.age_group_id}`)
    }

    async saveListRes(payload) {
        return await api().post(`settings/program/draw/applications/save`, payload)
    }

    async saveListResFig(payload) {
        return await api().post(`settings/figure/draw/users/save`, payload)
    }
}

export default new DistiplineSettingsService()
