import React from 'react';
import s from './styles.module.css'

const SportsmenItem = ({im, fam, otch, is_reserve}) => {
    return (
        <div className={s.sportsmen_item}>
            <div className={s.sportsmen_item_1}>
                {`${fam} ${im} ${otch}`}
            </div>
            <div className={s.sportsmen_item_2}>
                {is_reserve ? 'Резервный' : 'Основной'}
            </div>
        </div>
    );
};

export default SportsmenItem;
