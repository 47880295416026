import React, {useState} from 'react';
import {FormControl, MenuItem, Select} from "@mui/material";
import {observer} from "mobx-react-lite";

const SelecteFigure = observer(({figure, id, onSelect}) => {
    const [value, setValue] = useState(id)
    return (
        <FormControl fullWidth sx={{
            maxWidth: '230px',
            minWidth: '230px',
            height: '45px'
        }}>
            <Select
                placeholder={'Фигуры'}
                displayEmpty
                value={value || null}
                onChange={(e) => {
                    setValue(e.target.value)
                }}
                inputProps={{'aria-label': 'Without label'}}
                sx={{
                    height: '45px',

                    backgroundColor: '#fff'
                }}
                labelId="demo-simple-select-label"
            >

                <MenuItem sx={{
                    display: 'none'
                }} hidden disabled selected value={null}>Фигуры</MenuItem>
                {figure?.map((el, ind) => <MenuItem key={el.figure_id + 'm' + (ind * 30)}
                                                    onClick={() => onSelect(el.figure_id)}
                                                    value={el.figure_id}>{el.figure_name}</MenuItem>)}
            </Select>
        </FormControl>
    );
});

export default SelecteFigure;
