import {makeAutoObservable} from "mobx";
import GlobalService from "./services/global.service";
import {toast} from "react-toastify";

export class GlobalStore {
    status = {}
    isOpen = true
    list = []

    constructor() {
        makeAutoObservable(this)
    }

    setStatus(status) {
        this.status = status
    }

    setOpen(isOpen) {
        this.isOpen = isOpen
    }

    setList(list) {
        this.list = list
    }

    async getStatus() {
        try {
            const res = await GlobalService.getStatus()
            this.setStatus(res.data)
            this.setOpen(res.data?.is_created)
        } catch (e) {
            console.log(e)
        }
    }

    async getList() {
        try {
            const res = await GlobalService.getList()
            this.setList(res.data.competitions)
        } catch (e) {
            console.log(e)
        }
    }

    async chooseSelect(id) {
        try {
            const res = await GlobalService.chooseSelect(id)

            if (res.data.status === "ok") {
                this.setOpen(!res.data?.is_created)
            } else {
                toast.error(res.data?.msg || 'Ошибка')
            }

        } catch (e) {
            console.log(e)
        }
    }
}
