import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog({setOpen, open, handlerConfirm, title, status = '', actions}) {
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            {status === 'question' ? <Dialog
                id={'test'}
                sx={{
                    maxWidth: '100% !important',
                }}
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>

                <DialogActions sx={{
                    width: '100%',
                    flexDirection: 'column'
                }}>
                    <div style={{
                        // display: 'flex',
                        width: '100%'
                    }}>
                        {actions?.map((el, i) => <Button key={i} sx={{
                            // whiteSpace: 'nowrap',
                            // width: '100%',
                            // margin: '10px'
                            margin: i === 1 ? '10px 0 !important' : 0
                        }} variant="contained" color={"primary"} fullWidth onClick={() => handlerConfirm(el.answer_id)}
                                                         autoFocus>
                            {el.answer_text}
                        </Button>)}
                    </div>
                    <div style={{
                        display: 'flex',
                        width: '100%',
                        margin: '0 20px'
                    }}>
                        <Button sx={{
                            whiteSpace: 'nowrap',
                            width: '100%',
                            // margin: '0 10px'
                        }} fullWidth variant="contained" color={"error"} onClick={handleClose}>Отмена</Button>
                    </div>
                </DialogActions>
            </Dialog> : <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <DialogActions>
                    <Button variant="contained" color={"error"} onClick={handleClose}>Отмена</Button>
                    <Button variant="contained" color={"primary"} onClick={handlerConfirm} autoFocus>
                        Ок
                    </Button>
                </DialogActions>
            </Dialog>}

        </>
    );
}
