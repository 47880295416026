import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import {toast} from "react-toastify";
import { useEffect } from 'react';
import { useState } from 'react';
import api from "../../../api/api";
import s from './styles.module.css'

export default function AlertDialogCustomButton({setOpen, open, handlerRedButton, handlerBlueButton, redButton, blueButton, couchTypeId, couchTypeNumber}) {
    const [coachList, setCoachList] = useState([])

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        async function getListCoach() {
            await api().get(`/devices_ip`).then((res) => {
                setCoachList(res?.data)
                console.log(coachList)
            })
        }
        getListCoach()
    }, [couchTypeId])

    const selectDevice = (ip) => {
        async function fetchData() {
            await api().get(`/device_select/${couchTypeId}/${couchTypeNumber}/${ip}`).then(async (res) => {
                if (res?.data?.status === 'ok') {
                    toast.success('Запрос на вход отправлен')
                    setOpen(false)
                }
                if (res?.data?.status === 'error') {
                    toast.error(res?.data?.msg)
                    setOpen(false)
                }
            })
        }
        fetchData()
    }

    const handleReload = () => {
        async function fetchData() {
            await api().get(`/device_reload/${couchTypeId}/${couchTypeNumber}`).then((res) => {
                if (res?.data?.status === 'ok') {
                    toast.success('Запрос на перезагрузку страницы отправлен')
                    setOpen(false)
                }
                if (res?.data?.status === 'error') {
                    toast.error(res?.data?.msg)
                    setOpen(false)
                }
            })
        }
        fetchData()
    }
    return (
        <>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <CloseIcon onClick={handleClose} sx={{
                    margin: '10px',
                    fontSize: '24px',
                    cursor: 'pointer'
                }}/>
                <DialogActions style={{'display': 'flex', 'justifyContent': 'space-around'}}>
                    <Button variant="contained" color={"primary"} onClick={handlerBlueButton} autoFocus>
                        {blueButton}
                    </Button>
                    {/* <Button variant="contained" color={"primary"} onClick={handleReload} autoFocus>
                        Перезагрузить страницу
                    </Button> */}
                    <Button variant="contained" color={"error"} onClick={handlerRedButton}>{redButton}</Button>
                </DialogActions>
                <div>
                    <div className={s.header_coach}>Назначить устройству:</div>
                    <div className={s.listCoach}>
                        {coachList.map((coach) => {
                            return (
                                <button 
                                    className={s.device_number}
                                    onClick={() => {selectDevice(coach?.ip_address)}}
                                    style={{
                                        'background': coach?.status == 'connect' ? '#1976d2' : '#9ca1a7',
                                        'cursor':  coach?.status == 'connect' ? 'pointer' : 'default'
                                    }}
                                    disabled={coach?.status == 'disconnect' ? true : false}
                                >
                                    {coach?.device_number}
                                </button>
                            )
                        })}
                    </div>
                </div>
            </Dialog>
        </>
    );
}
