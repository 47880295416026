import React, {useEffect, useState} from 'react';
import s from './styles.module.css'
import Wrapper from "../../../components/wrapper";
import {observer} from "mobx-react-lite";
import {FormControl, MenuItem, Select} from "@mui/material";
import {useStore} from "../../../hooks/useStore";
import api from "../../../api/api";

const Header = observer(({
                             ageGroupSelect,
                             setAgeGroupSelect,
                             setChooseDistiplines,
                             chooseDistipline,
                         }) => {
    const store = useStore()
    const [distiplinesItems, setDistiplinesItems] = useState([])
    const [distiplinesSubItems, setDistiplinesSubItems] = useState([])
    const [loadingDisciplines, setLoadingDisciplines] = useState(false)

    useEffect(() => {
        const getDistiplines = async () => {
            setLoadingDisciplines(true)
            try {
                const res = await api().get(`/reports/age_group_report`)
                const res2 = await api().get(`/reports/discipline_report`)
                setDistiplinesSubItems(res2?.data?.discipline_reports)
                setDistiplinesItems(res?.data?.age_groups)
            } catch (e) {
                console.log(e)
            } finally {
                setLoadingDisciplines(false)
            }
        }
        getDistiplines()
    }, [])


    return (
        <div className={s.header}>
            <Wrapper>
                <div className={s.header_data}>
                    <FormControl fullWidth sx={{
                        maxWidth: '254px',
                        height: '45px'
                    }}>
                        <Select
                            placeholder={'Возрастная группа'}
                            displayEmpty
                            disabled={loadingDisciplines}
                            value={ageGroupSelect}
                            onChange={(e) => {
                                setAgeGroupSelect(e.target.value)
                            }}
                            inputProps={{'aria-label': 'Without label'}}
                            sx={{
                                height: '45px',
                                backgroundColor: '#fff'
                            }}
                            labelId="demo-simple-select-label"
                        >

                            <MenuItem sx={{
                                display: 'none'
                            }} hidden disabled selected value={null}>Возрастная группа</MenuItem>
                            {distiplinesItems?.map((el, i) => <MenuItem key={el?.age_group_report_id}
                                                                        value={el?.age_group_report_id}>{`${el?.age_group_report_name}`}</MenuItem>)}

                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{
                        maxWidth: '289px',
                        height: '45px',
                        marginLeft: '22px',
                        marginRight: '22px',
                    }}>
                        <Select
                            placeholder={'Дисциплина'}
                            disabled={loadingDisciplines}
                            displayEmpty
                            value={chooseDistipline}
                            onChange={(e) => {
                                setChooseDistiplines(e.target.value)

                            }}
                            inputProps={{'aria-label': 'Without label'}}
                            sx={{
                                height: '45px',
                                backgroundColor: '#fff'
                            }}
                            labelId="demo-simple-select-label"
                        >

                            <MenuItem sx={{
                                display: 'none'
                            }} hidden disabled selected value={null}>Дисциплина</MenuItem>
                            {distiplinesSubItems.map((el, i) => <MenuItem
                                key={el.discipline_report_id}
                                value={el.discipline_report_id}>{el.discipline_report_name}</MenuItem>)}

                        </Select>
                    </FormControl>
                    <div>
                        <h1 className={s.name}>{store?.global?.status?.competition_name || ''}</h1>
                    </div>
                </div>
            </Wrapper>

        </div>
    );
});

export default Header;
