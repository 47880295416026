import React, {useEffect, useState} from 'react';
import s from "../styles.module.css";
import SelecteFigure from "../select_figure";
import {observer} from "mobx-react-lite";
import {useStore} from "../../../../hooks/useStore";

const TableFigure = observer(({distiplineSelect}) => {
    const store = useStore()

    const [table, setTable] = useState({})
    const [figure, setFigure] = useState({})
    const [valuesNumber, setValuesNumber] = useState([])

    const handlerSetFigure = async (id, position) => {
        await store.distipline_settings.setFigureTable(id, position)
    }
    const handlerSaveFigure = async () => {
        await store.distipline_settings.setSaveFigure(valuesNumber)
    }

    useEffect(() => {
        const getBrigade = async () => {
            await store.distipline_settings.getTable(distiplineSelect.discipline_age_group_id)
            await store.distipline_settings.getFigure()
        }

        if (distiplineSelect === null) return
        getBrigade()
    }, [distiplineSelect?.discipline_age_group_id])

    useEffect(() => {
        setTable(store.distipline_settings.table)
    }, [store.distipline_settings.table])

    useEffect(() => {
        setFigure(store.distipline_settings.figure)
    }, [store.distipline_settings.figure])

    return (
        <div className={s.table}>
            <div className={s.figure}>
                <div className={s.figure_item}/>
                {table?.figures?.map((el, i) => <SelecteFigure
                    onSelect={(id) => handlerSetFigure(id, el.discipline_figure_id)}
                    id={el.figure_id} key={el.figure_id + 'ind' + i} figure={figure}/>)}
            </div>

            <div className={s.brigade}>
                <div className={s.brigade_top} style={{
                    gridTemplateColumns: `repeat(${table?.brigades?.length},1fr)`
                }}>
                    {table?.brigades?.map((el, i) => {
                        return <div className={s.brigade_top_item} key={el.brigade_id}>
                            <div className={s.brigade_top_item_top}>{el.brigade_name}</div>

                            <div className={s.brigade_top_item_top_content}>
                                {table?.figures?.map((fig, ind) => {
                                    const findSome = el?.figures?.some(s => s.discipline_figure_id === fig.discipline_figure_id)
                                    const findSomev2 = el?.figures?.find(s => s.discipline_figure_id === fig.discipline_figure_id)

                                    const findValue = valuesNumber.find((f) => f?.figure_start_id === findSomev2?.figure_start_id)

                                    return <div
                                        key={el.brigade_id + 'w' + fig.figure_id}
                                        className={findSome ? s.brigade_top_item_top_content_item_input : s.brigade_top_item_top_content_item}>
                                        {findSome ?
                                            <input type="number" className={s.input_table} min={0}
                                                   onChange={(e) => {
                                                       if (findValue) {
                                                           setValuesNumber(valuesNumber.map((el) => el.figure_start_id === findValue.figure_start_id ? ({
                                                               ...el,
                                                               start_number: e.target.value
                                                           }) : el))
                                                       } else {
                                                           setValuesNumber([...valuesNumber, {
                                                               figure_start_id: findSomev2.figure_start_id,
                                                               start_number: e.target.value
                                                           }])
                                                       }

                                                   }}
                                                   value={findValue ? findValue.start_number : findSomev2?.start_number}/>
                                            : ''}
                                    </div>
                                })}

                            </div>
                        </div>
                    })}
                </div>
            </div>

            <div className={s.btn_box}>
                <div className={s.btn} onClick={handlerSaveFigure}>Сохранить</div>
            </div>
        </div>
    );
});

export default TableFigure;
