import axios from "axios";
import {BASE_API_URL} from "../constants";

export default function api() {
    const token = localStorage.getItem('token')
    const instance = axios.create({
        baseURL: BASE_API_URL,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    instance.interceptors.response.use((response) => {
        return response;
    }, (error) => {
        console.log(error)
        if (error.response.status === 401 || error.response.status === 403) {
            localStorage.removeItem('token');
            window.location.reload();
            // Токен умер
            // Обновить токен
            // Повторить запрос
        }

        return Promise.reject(error);
    });

    return instance;
}
