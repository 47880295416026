import api from "../../api/api";

class GlobalService {
    async getStatus() {
        const res = await api().get(`competition/status`)
        return res
    }

    async getList() {
        const res = await api().get(`competition/competitions`)
        return res
    }

    async chooseSelect(id) {
        const res = await api().get(`competition/select/${id}`)
        return res
    }
}

export default new GlobalService()
