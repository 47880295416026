import React, {useEffect, useState} from 'react';
import s from './styles.module.css'
import classNames from "classnames";
import {observer} from "mobx-react-lite";
import {useStore} from "../../../../hooks/useStore";

const SettingReserv = observer(({distiplineSelectFull, discipline_id, age_group_id}) => {
    const store = useStore()

    const [data, setData] = useState([])

    const getReserv = async () => {
        await store.distipline_settings.getListResFig((data) => setData(data), {
            age_group_id: age_group_id
        })
    }

    const handleChangeData = (id, path, value) => {
        setData(data?.map((el) => el.application_figure_id === id ? ({...el, [path]: value}) : el))
    }

    const handleSave = async () => {
        await store.distipline_settings.saveListResFig(() => getReserv(), data?.map((el) => {
            return {
                competition_figure_id: el?.application_figure_id,
                competition_draw: +el.competition_draw,
                is_competition_draw_out: !el.competition_draw_out ? false : true,
                is_delete: el.is_delete
            }
        }))
    }


    useEffect(() => {
        getReserv()
    }, [])
    return (
        <div className={s.content}>
            <div className={s.header}>
                <div className={s.btn} onClick={handleSave}>
                    Сохранить
                </div>
            </div>

            <div className={s.table}>
                {data?.map((el, i) => {
                    return <div className={s.header_table} style={{
                        borderTop: i !== 0 && 'none'
                    }}>
                        <div className={classNames(s.item, s.item_action)}
                             onClick={() => handleChangeData(el.application_figure_id, 'is_delete', !!!el.is_delete)}
                             style={{
                                 minWidth: '54px'
                             }}
                        >
                            <p className={s.item_title}>{el?.is_delete ? 'X' : ''}</p>
                            {i === 0 && <p onClick={(e) => e.stopPropagation()} style={{
                                cursor: 'default',
                            }} className={s.header_title}>Del</p>}
                        </div>

                        <div className={classNames(s.item, s.item_action)}
                             onClick={() => handleChangeData(el.application_figure_id, 'competition_draw_out', !!!el.competition_draw_out)}
                             style={{
                                 minWidth: '54px'
                             }}
                        >
                            <p className={s.item_title}>{el?.competition_draw_out ? 'Вн.к' : ''}</p>
                            {i === 0 && <p onClick={(e) => e.stopPropagation()} style={{
                                cursor: 'default',
                            }} className={s.header_title}>Вн.к</p>}
                        </div>

                        <div className={classNames(s.item, s.item_action)}
                             style={{
                                 minWidth: '54px',
                                 padding: 0
                             }}
                        >
                            <p className={s.item_title}>
                                <input type="text" value={el?.competition_draw} onChange={(event) => {
                                    const input = event.target.value;
                                    const regex = /^-?\d*$/;

                                    if (regex.test(input)) {
                                        handleChangeData(el.application_figure_id, 'competition_draw', input)
                                    }
                                }} className={s.item_title}/>
                            </p>
                            {i === 0 && <p onClick={(e) => e.stopPropagation()} style={{
                                cursor: 'default',
                            }} className={s.header_title}>Ст. н.</p>}
                        </div>

                        <div className={s.item}
                             style={{
                                 minWidth: '150px',
                             }}
                        >
                            <p className={s.item_title}>{el?.user_name}</p>
                            {i === 0 && <p className={s.header_title}>ФИО</p>}
                        </div>

                        <div className={s.item}
                             style={{
                                 minWidth: '150px',
                             }}
                        >
                            <p className={s.item_title}>{el?.user_year}</p>
                            {i === 0 && <p className={s.header_title}>Гр</p>}
                        </div>

                        <div className={s.item}
                             style={{
                                 minWidth: '150px',

                             }}
                        >
                            <p className={s.item_title}>{el?.user_category}</p>
                            {i === 0 && <p className={s.header_title}>Разряд</p>}
                        </div>

                        <div className={s.item}
                             style={{
                                 minWidth: '150px',

                             }}
                        >
                            <p className={s.item_title}>{el?.user_command}</p>
                            {i === 0 && <p className={s.header_title}>Команда</p>}
                        </div>
                    </div>

                })}
            </div>
        </div>
    );
});

export default SettingReserv;
