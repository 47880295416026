import React, {useState} from 'react';
import s from './styles.module.css'
import classNames from "classnames";
import {useStore} from "../../../../hooks/useStore";
import AlertDialog from "../../../../components/dialog_modal";
import {Tooltip} from "@mui/material";
import GroupsIcon from '@mui/icons-material/Groups';

const CommandItem = ({
                         isReferee,
                         rank,
                         competition_draw,
                         poins_behind,
                         command_name,
                         activeCommand,
                         index,
                         competition_application_name,
                         total_difficulty_result,
                         selectedCommand,
                         element_result,
                         impression_result,
                         id,
                         handlerSelectCommand,
                         total_result,
                         is_processing,
                         doubleStartJudging
                     }) => {
    const store = useStore()
    const selectedBox = selectedCommand === id ? s.selected : ''
    const [openModal, setOpenModal] = useState(false)

    const handlerConfirm = async () => {
        await doubleStartJudging(id)
        setOpenModal(false)
    }
    // doubleStartJudging
    return (
        <div className={s.item}
             onClick={() => {
                 handlerSelectCommand(id)
             }}>
            <AlertDialog title={store.judging?.check?.msg} status={store.judging?.check?.status}
                         actions={store.judging?.check?.answers} open={openModal} setOpen={setOpenModal}
                         handlerConfirm={async (id) => {
                             if (store.judging?.check?.status === 'question') {
                                 const res = await store.judging.confirmConfirm(id, activeCommand)
                             }
                             handlerConfirm()
                         }}/>

            <div className={s.number_command_name}>
                <div onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                }} className={classNames(s.number, is_processing && s.active_command)} onDoubleClick={(e) => {
                    // if(store.judging.status)
                    e.preventDefault()
                    e.stopPropagation()
                    if (store.judging.status.status !== 'start' || isReferee) return
                    store.judging.checkJudging(setOpenModal, activeCommand)
                }}>{competition_draw}</div>

                <div className={classNames(s.command_name, selectedBox)}>{competition_application_name || '---'}</div>

                <Tooltip title={command_name}>
                    <div className={s.team_icon}>
                        <GroupsIcon/>
                    </div>
                </Tooltip>
            </div>

            <div className={classNames(s.total_name, selectedBox)}>{total_difficulty_result || '---'}</div>
            <div className={classNames(s.elements_name, selectedBox)}>{element_result || '---'}</div>
            <div className={classNames(s.artistik_name, selectedBox)}>{impression_result || '---'}</div>
            <div className={classNames(s.point_name, selectedBox)}>{total_result || '---'}</div>
            <div className={classNames(s.total_name, selectedBox)}>{poins_behind || '---'}</div>
            <div className={classNames(s.rank_name, selectedBox)}>{rank || '---'}</div>
        </div>
    );
};

export default CommandItem;
