import React, {useEffect, useState} from 'react';
import s from './styles.module.css'
import {observer} from "mobx-react-lite";
import {useStore} from "../../../hooks/useStore";
import Row from "./row";
import Wrapper from "../../../components/wrapper";

const Left = observer(({ageGroupSelect}) => {
    const store = useStore()

    const [changedData, setChangedData] = useState([])
    const handleSave = async () => {
        await store.setting_sorev.saveDisciplineLeft(changedData)
    }

    useEffect(() => {
        setChangedData([])
        const getDisciplineLeft = async () => {
            await store.setting_sorev.getDisciplineLeft(ageGroupSelect)
        }
        if (ageGroupSelect === null) return
        getDisciplineLeft()
    }, [ageGroupSelect])
    return (
        <>
            {!store.setting_sorev.loading_disciplineLeft && <div className={s.test}>
                <h1 className={s.title}>Дисциплины</h1>
                <div className={s.left}>
                    {store.setting_sorev.discipline_left.map((el, index) => <Row {...el} index={index}
                                                                                 setChangedData={setChangedData}
                                                                                 changedData={changedData}
                                                                                 key={el.discipline_id}
                                                                                 age_group_id={ageGroupSelect}/>)}
                </div>
                <div className={s.btn_box}>
                    <div className={s.btn} onClick={handleSave}>Сохранить</div>
                </div>
            </div>}
        </>

    );
});

export default Left;
