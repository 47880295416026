import React, { useEffect, useState } from 'react';
import s from './styles.module.css'
import { observer } from "mobx-react-lite";
import { useStore } from "../../../hooks/useStore";
import classNames from "classnames";
import { toast } from "react-toastify";
import { Switch } from "@mui/material";
import FigureBox2 from "../figure_box_2";
import AlertDialog from "../../../components/dialog_modal";
import api from "../../../api/api";

const FigureBox = observer(({ 
        id_disciplin, 
        id_group, 
        socketData, 
        judgleStatus,
        setOpenDialogCustom,
        setCoachTypeId,
        setCoachTypeNumber, 
    }) => {
    const { figure } = useStore()

    const [value, setValue] = useState('')
    const [checked, setChecked] = useState(false)
    const [openDialog, setOpenDialog] = React.useState(false);
    const [sportsmanName, setSportsmanName] = React.useState(false);
    const [applicationFigureId, setApplicationFigureId] = React.useState(false);

    const [figureData, setFigureData] = useState(figure)

    const handleClickSearch = async () => {
        if (value === '') {
            // toast.error('Минимальная длина один символ')
            await figure.getCommand(id_group)
            return
        }
        await figure.getCommandByName(value.trim(), id_group)
    }

    const handleClickRecalclulate = async () => {
        await figure.getRecalclulate(id_group).then((res) => {
            if (res?.status === 'ok') {
                figure.getCommand(id_group)
                toast.success('Готово')
            }
        })
    }

    useEffect(() => {
        figure.getCommand(id_group)
        figure.getFigure(id_group)
    }, [id_group, checked])

    useEffect(() => {
        if (checked) return
        if (socketData?.method === "update_user_figure") {
            const getUpdateScor = async () => {
                const res = await api().get(`/figure/user/${socketData?.application_figure_id}`)

                setFigureData({
                    ...figureData,
                    commands: {
                        ...figureData?.commands,
                        application_figure: figureData?.commands?.application_figure?.map((el) => el?.application_figure_id === res.data?.application_figure_id ? (res.data) : el)
                    }
                })
            }
            getUpdateScor()

        }
    }, [socketData, figure, checked])

    useEffect(() => {
        figure.getCommand(id_group)
    }, [id_group])

    useEffect(() => {
        setFigureData(figure)
    }, [figure])

    const clearScore = async () => {
        setOpenDialog(false)
        await api().get(`/figure/clean_score/${applicationFigureId}`).then((res) => {
            if (res?.data?.status === 'ok') {
                toast.success('Оценки спортсмена очищены')
            }
         
            if (res?.data?.status === 'error') {
                toast.error(res?.data?.msg)
            }
        })
        const res = await api().get(`/figure/user/${applicationFigureId}`)
        setFigureData({
            ...figureData,
            commands: {
                ...figureData?.commands,
                application_figure: figureData?.commands?.application_figure?.map((el) => el?.application_figure_id === res.data?.application_figure_id ? (res.data) : el)
            }
        })
    }

    return (
        <div className={s.warpper}>
            <AlertDialog
              title={`Очистить все оценки спортсмена ${sportsmanName}?`}
              open={openDialog} setOpen={setOpenDialog} handlerConfirm={clearScore}/>
            <div className={s.content}>

                <div className={s.navigate}>
                    <div className={s.search}>
                        {!checked && <>
                            <input className={s.input} type="text" value={value}
                                onChange={(e) => setValue(e.target.value)} />
                            <div className={s.btn} onClick={handleClickSearch}>
                                Найти
                            </div>
                            <div className={s.btn_calc} onClick={handleClickRecalclulate}>
                                Подсчитать
                            </div>
                        </>}
                    </div>

                    <div className={s.switch}>
                        <p>{checked ? 'Спортсмены' : 'Фигуры'}</p>
                        <Switch checked={checked} onChange={(e) => setChecked(e.target.checked)} />
                    </div>
                </div>

                {!checked ? <div className={s.table}>
                    {figureData?.commands?.application_figure?.map((el, i) => {
                        return <div className={s.header} key={i}>
                            <div className={s.item} style={{ minWidth: '55px', fontSize: '20px', fontWeight: 508 }}
                                onDoubleClick={() => {
                                    setOpenDialog(true)
                                    setSportsmanName(el?.user_name)
                                    setApplicationFigureId(el?.application_figure_id)
                                }}
                            >{el.draw}</div>
                            <div className={s.item} style={{ minWidth: '150px' }}>
                                <p className={s.item_title}>{el.user_name}</p>
                                {i === 0 && <p className={s.header_title}>ФИО</p>}
                            </div>
                            <div className={s.item} style={{ minWidth: '70px' }}>
                                <p className={s.item_title}>{el.user_year}</p>
                                {i === 0 && <p className={s.header_title}>г.р.</p>}
                            </div>
                            <div className={s.item} style={{ minWidth: '72px' }}>
                                <p className={s.item_title}>{el.user_category}</p>
                                {i === 0 && <p className={s.header_title}>Раз</p>}
                            </div>
                            <div className={s.item} style={{ minWidth: '100px' }}>
                                <p className={s.item_title}>{el.user_command}</p>
                                {i === 0 && <p className={s.header_title}>Команда</p>}
                            </div>
                            <div className={s.item} style={{ minWidth: '82px' }}>
                                <p className={s.item_title}>{el.f1_result !== 'null' ?  el.f1_result : null}</p>
                                {i === 0 && <p className={s.header_title}>{figure?.commands?.figures['f1_result']}</p>}
                            </div>
                            <div className={s.item} style={{ minWidth: '82px' }}>
                                <p className={s.item_title}>{el.f2_result !== 'null' ?  el.f2_result : null}</p>
                                {i === 0 && <p className={s.header_title}>{figure?.commands?.figures['f2_result']}</p>}
                            </div>
                            <div className={s.item} style={{ minWidth: '82px' }}>
                                <p className={s.item_title}>{el.f3_result !== 'null' ?  el.f3_result : null}</p>
                                {i === 0 && <p className={s.header_title}>{figure?.commands?.figures['f3_result']}</p>}
                            </div>
                            <div className={s.item} style={{ minWidth: '82px' }}>
                                <p className={s.item_title}>{el.f4_result !== 'null' ?  el.f4_result : null}</p>
                                {i === 0 && <p className={s.header_title}>{figure?.commands?.figures['f4_result']}</p>}
                            </div>
                            <div className={s.item} style={{ minWidth: '82px' }}>
                                <p className={s.item_title}>{el.total_result !== 'null' ?  el.total_result : null}</p>
                                {i === 0 && <p className={s.header_title}>Резуль</p>}
                            </div>
                            <div className={s.item} style={{ minWidth: '82px' }}>
                                <p className={s.item_title}>{el.poins_behind !== 'null' ?  el.poins_behind : null}</p>
                                {i === 0 && <p className={s.header_title}>Отст.</p>}
                            </div>
                            <div className={s.item} style={{ minWidth: '74px' }}>
                                <p className={s.item_title}>{el.rank !== 'null' ?  el.rank : null}</p>
                                {i === 0 && <p className={s.header_title}>Rank</p>}
                            </div>
                        </div>
                    })}

                </div> : <FigureBox2 
                            socketData={socketData} 
                            id={id_group} 
                            judgleStatus={judgleStatus}
                            setOpenDialogCustom={setOpenDialogCustom} 
                            setCoachTypeId={setCoachTypeId} 
                            setCoachTypeNumber={setCoachTypeNumber}
                        />}
            </div>
        </div>
    );
});

export default FigureBox;
