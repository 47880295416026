import React, {useState} from 'react';
import s from './styles.module.css'
import {Container, Grid, TextField, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {toast} from "react-toastify";
import axios from "axios";

const Auth = ({setIsAuth}) => {
    const [data, setData] = useState({
        login: 'Admin',
        password: ""
    })
    const handleLogin = async () => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL_PORT}authentication/authenticate`, {...data})
            localStorage.setItem('token', res.data.token)
            setIsAuth(true)
        } catch (e) {
            console.log(e)
            toast.error('Ошибка')
        }
        // Обработка логина
    };

    return (
        <div className={s.main}>
            <Container maxWidth="xs">
                <Typography variant="h4" align="center" gutterBottom>
                    Авторизация
                </Typography>
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                    {/*<Grid item xs={12}>*/}
                    {/*    <TextField value={data.login} onChange={(e) => setData({...data, login: e.target.value})}*/}
                    {/*               label="Логин" variant="outlined" fullWidth/>*/}
                    {/*</Grid>*/}
                    <Grid item xs={12}>
                        <TextField value={data.password} onChange={(e) => setData({...data, password: e.target.value})}
                                   label="Пароль" type="password" variant="outlined" fullWidth/>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" fullWidth onClick={handleLogin}>
                            Вход
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default Auth;
