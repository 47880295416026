import React, {useEffect} from 'react';
import s from './styles.module.css'
import {observer} from "mobx-react-lite";
import {useStore} from "../../../hooks/useStore";

const Factor = observer(({distiplineSelect}) => {
    const store = useStore()

    useEffect(() => {
        const getFactors = async () => {
            await store.distipline_settings.getFactors(distiplineSelect.discipline_age_group_id)
            await store.distipline_settings.getFactorsCoff(distiplineSelect.discipline_age_group_id)
        }
        if (distiplineSelect === null) return
        getFactors()
    }, [distiplineSelect])
    return (
        <div className={s.content}>
            <div className={s.content_left}>
                <h2>Факторы</h2>
                <div className={s.content_left_content}>
                    {store.distipline_settings.factors?.map((el) => <div className={s.item}
                                                                         key={el.age_group_discipline_factor_id}>
                        <div className={s.name}>{el.name}</div>
                        <div className={s.value}>{el.value}</div>
                    </div>)}
                </div>
            </div>
            <div className={s.content_right}>
                <h2>Коэффициент сложности (КС, БО)</h2>
                <div className={s.content_right_content}>
                    {store.distipline_settings?.factors_coff?.map((el) => <div className={s.item}
                                                                               key={el.id}>
                        <div className={s.name}>{el.name}</div>
                        <div className={s.value}>{el.value}</div>
                    </div>)}
                </div>
            </div>
        </div>
    );
});

export default Factor;
