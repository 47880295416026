import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import api from "../../../api/api";
import { useStore } from "../../../hooks/useStore";
import s from './styles.module.css';
import TableFigure from "./table";

const JudgesFigure = observer(({ageGroupSelect, distiplineSelect}) => {
    const store = useStore()

    const [chooseValue, setChooseValue] = useState([])
    const [forceUpdate, setForceUpdate] = useState(false)
    const [forceUpdatev2, setForceUpdatev2] = useState(false)
    const [brigade, setBrigade] = useState({})
    const [listSelect, setListSelect] = useState([])
    const [listData, setListData] = useState([])
    const [date, setDate] = useState(null)
    const [assistant, setAssistant] = useState({})
    const [assistantId, setAssistantId] = useState()

    const handlerSetBrigade = async () => {
        setForceUpdatev2(true)
        await store.distipline_settings.setBrigadeFigure(store.distipline_settings?.brigade.figure_brigades_id, brigade)
        setForceUpdatev2(false)
    }

    const handleDateChange = (newValue) => {
        const formattedDate = dayjs(newValue?.$d).format('YYYY-M-D');
        setDate(formattedDate);
    };

    const saveList = async () => {
        try {
            await api().post(`/settings/figure/coaches/save`, chooseValue)
            if (date) {
                await api().get(`settings/figure/date/save/${date}/${ageGroupSelect}`)
            }
            if (assistantId) {
                await api().get(`settings/figure/assistant/save/${assistantId}/${ageGroupSelect}`)
            }
            toast.success('Сохранено')
        } catch (e) {
            console.log(e)
            toast.error('Ошибка')
        }
    }

    const handleAssistantChange = (event) => {
        setAssistantId(event.target.value);
    };

    // console.log(chooseValue)
    useEffect(() => {
        const getListData = async () => {
            function groupById(arr) {
                return arr.reduce((obj, element) => {
                    obj[element.number_brigade] = obj[element.number_brigade] || [];
                    obj[element.number_brigade].push(element);
                    return obj;
                }, {});
            }

            setForceUpdate(true)
            try {
                await api().get(`settings/figure/assistant/${ageGroupSelect}`).then((res) => {
                    setAssistant(res.data)
                })
                const res2 = await api().get(`/settings/coaches/`)
                setListSelect(res2.data?.coaches)
                const res = await api().get(`/settings/figure/coaches/${ageGroupSelect}`)
                setListData(groupById(res.data?.figure_brigade_coach))
                const res3 = await api().get(`settings/figure/date/${ageGroupSelect}`)
                setDate(res3.data.date)
                setForceUpdate(false)
            } catch (e) {
                console.log(e)
            }
        }
        getListData()
    }, [ageGroupSelect, forceUpdatev2])


    useEffect(() => {
        const getBrigade = async () => {
            await store.distipline_settings.getBrigade(distiplineSelect.discipline_age_group_id)
        }

        if (distiplineSelect === null) return
        getBrigade()
    }, [distiplineSelect?.discipline_age_group_id])

    useEffect(() => {
        setBrigade({
            count_brigade: store.distipline_settings?.brigade?.count_brigades || 0,
            count_coach_brigade: store.distipline_settings?.brigade?.count_coach_brigade || 0,
        })
    }, [store.distipline_settings.brigade])

    return (
        <div className={s.content}>
            <div className={s.choose_box}>
                <div className={s.choose_box_item}>
                    <div>Кол-во бригад</div>
                    <input type="number" min={1} max={5}
                           onChange={(e) => setBrigade({...brigade, count_brigade: e.target.value})}
                           value={brigade?.count_brigade}/>
                </div>
                <div className={s.choose_box_item}>
                    <div>Кол-во судей в бригаде</div>
                    <input type="number" onChange={(e) => setBrigade({...brigade, count_coach_brigade: e.target.value})}
                           value={brigade?.count_coach_brigade}/>
                </div>

                <div className={s.btn} onClick={handlerSetBrigade}>
                    Сохранить
                </div>
            </div>
            {(!forceUpdate && !forceUpdatev2) && <div className={s.brigade_select_box}>
                {Object.keys(listData)?.map((el) => {

                    return <div>
                        <h1>Бригада {el}</h1>
                        <div className={s.select_box}>
                            {listData[el]?.map((sel2, i) => {
                                // console.log(sel2)
                                return <select key={i} defaultValue={sel2?.user_id || ''} onChange={(e) => {
                                    const findValue = chooseValue?.find(f => +f.figure_brigade_coach_id === +sel2?.figure_brigade_coach_id)
                                    if (findValue) {
                                        setChooseValue(chooseValue?.map((m) => +m.figure_brigade_coach_id === +findValue?.figure_brigade_coach_id ? ({
                                            ...m,
                                            user_id: e.target.value === '-' ? null : e.target.value
                                        }) : m))
                                    } else {
                                        setChooseValue([...chooseValue, {
                                            figure_brigade_coach_id: sel2.figure_brigade_coach_id,
                                            user_id: e.target.value === '-' ? null : e.target.value
                                        }])
                                    }
                                }
                                }>
                                    <option value={'' || null}>-</option>
                                    {listSelect?.map((sel, ind) => {
                                        // console.log(sel)
                                        return <option
                                            key={ind}
                                            value={sel.id}
                                        >{`${sel.fam} ${sel.im} ${sel.otch}`}</option>
                                    })}
                                </select>
                            })}
                        </div>
                    </div>
                })}
            </div>}

            <div className={s.save_settings}>
                <div className={s.date_wrapper}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Дата"
                            value={date && dayjs(date)}
                            format={'DD.MM.YYYY'}
                            sx={{
                                width: '200px',
                                backgroundColor: 'white',
                            }}
                            slotProps={{ textField: { size: 'small' } }}
                            onChange={(newValue) => handleDateChange(newValue)}
                        />
                    </LocalizationProvider>
                    {(!forceUpdate && !forceUpdatev2) &&
                        <div className={s.item_select_assistant}>
                            <select defaultValue={assistant.id || ''}
                                onChange={(e) => {
                                        setAssistantId(e.target.value === '-' ? null : e.target.value)
                                    }
                                }
                            >
                                <option value={null}>
                                    -
                                </option>
                                {assistant.id && 
                                    <option value={assistant.id}>
                                        {assistant.fam} {assistant.im} {assistant.otch}
                                    </option>
                                }
                                {listSelect?.map((sel) => {
                                    return <option
                                        value={sel.id}
                                    >{`${sel.fam} ${sel.im} ${sel.otch}`}</option>
                                })}
                            </select>
                        </div>
                    }
                </div>
                <div className={s.btn} onClick={saveList}>
                    Сохранить
                </div>
            </div>

            {store.distipline_settings.loadingBrigade ? null : <TableFigure distiplineSelect={distiplineSelect}/>}

        </div>
    );
});

export default JudgesFigure;
