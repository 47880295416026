import {JudgingStore} from "./judging_store";
import {DistiplineSettingsStore} from "./distipline_settings.store";
import {Setting_sorevStore} from "./setting_sorev.store";
import {GlobalStore} from "./global.store";
import {FigureStore} from "./figure.store";


class Store {
    constructor() {
        this.judging = new JudgingStore()
        this.distipline_settings = new DistiplineSettingsStore()
        this.setting_sorev = new Setting_sorevStore()
        this.global = new GlobalStore()
        this.figure = new FigureStore()
    }
}

export const store = new Store()
