import {makeAutoObservable} from "mobx";
import Setting_sorevService from "./services/setting_sorev.service";
import {toast} from "react-toastify";

export class Setting_sorevStore {
    discipline_left = []
    loading_disciplineLeft = false
    loading_static = false
    static_res = []
    dynamic_res = []
    discipline = []

    constructor() {
        makeAutoObservable(this)
    }

    setLoadingDisciplineLeft(loading_disciplineLeft) {
        this.loading_disciplineLeft = loading_disciplineLeft
    }

    setLoadingStatic(loading_static) {
        this.loading_static = loading_static
    }

    setDisciplineLeft(discipline_left) {
        this.discipline_left = discipline_left
    }

    setStatic(static_res) {
        this.static_res = static_res
    }

    setDynamic(dynamic_res) {
        this.dynamic_res = dynamic_res
    }

    setDiscipline(discipline) {
        this.discipline = discipline
    }

    async removeDinamic(id) {
        try {
            const res = await Setting_sorevService.removeDinamic(id)
            if (res.data.status === "ok") {
                this.setDynamic(this.dynamic_res.filter((f) => f?.result_dinamic_type_id !== id))
                toast.success('Удалено', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            } else {
                toast.error(res.data.msg || 'Ошибка', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }

            // this.setDiscipline(res.data.disciplines)
        } catch (e) {
            console.log(e)
        }
    }

    async changeName(name, id) {
        try {
            const res = await Setting_sorevService.changeName(name, id)
            if (res.data.status === "ok") {
                toast.success('Сохраненно', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            } else {
                toast.error(res.data.msg || 'Ошибка', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        } catch (e) {
            console.log(e)
            toast.error('Ошибка', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    }

    async addedDiscipline(ds_id, res_id, id) {
        try {
            const res = await Setting_sorevService.addedDiscipline(ds_id, res_id, id)
            id !== null && await this.getDinamic(id)
            // this.setDiscipline(res.data.disciplines)
        } catch (e) {
            console.log(e)
        }
    }

    async removeDiscipline(idd, id) {
        try {
            const res = await Setting_sorevService.removeDiscipline(idd)
            id !== null && await this.getDinamic(id)
            // this.setDiscipline(res.data.disciplines)
        } catch (e) {
            console.log(e)
        }
    }

    async getDiscipline() {
        try {
            const res = await Setting_sorevService.getDiscipline()
            this.setDiscipline(res.data.disciplines)
        } catch (e) {
            console.log(e)
        }
    }

    async getDinamic(id) {
        // this.setLoadingStatic(true)
        try {
            const res = await Setting_sorevService.getDinamic(id)
            if (res.data.status === "error") {
                toast.error(res.data.msg || 'Ошибка', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                this.setDynamic([])
            } else {
                this.setDynamic(res.data.discipline_result_dinamic)
            }
        } catch (e) {
            console.log(e)
        } finally {
            // this.setLoadingStatic(false)
        }
    }

    async getStatic(id) {
        this.setLoadingStatic(true)
        try {
            const res = await Setting_sorevService.getStatic(id)
            if (res.data.status === "error") {
                toast.error(res.data.msg || 'Ошибка', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                this.setStatic([])
            } else {
                this.setStatic(res.data.discipline_result)
            }
        } catch (e) {
            console.log(e)
        } finally {
            this.setLoadingStatic(false)
        }
    }

    async addedDinamic(name, id) {
        // this.setLoadingDisciplineLeft(true)
        try {
            const res = await Setting_sorevService.addedDinamic(name)
            id !== null && await this.getDinamic(id)
            toast.success('Добавлено', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            // this.setDisciplineLeft(res.data.discipline_stage)
        } catch (e) {
            toast.error('Ошибка', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            console.log(e)
        } finally {
            // this.setLoadingDisciplineLeft(false)
        }
    }

    async getDisciplineLeft(id) {
        this.setLoadingDisciplineLeft(true)
        try {
            const res = await Setting_sorevService.getDisciplineLeft(id)
            this.setDisciplineLeft(res.data.discipline_stage)
        } catch (e) {
            console.log(e)
        } finally {
            this.setLoadingDisciplineLeft(false)
        }
    }

    async saveDisciplineLeft(payload) {
        // this.setLoadingDisciplineLeft(true)
        try {
            const res = await Setting_sorevService.saveDisciplineLeft(payload)
            if (res.data.status === "error") {
                toast.error(res.data.msg || 'Ошибка', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            } else {
                toast.success('Сохранено', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
            // this.setDisciplineLeft(res.data.discipline_stage)
        } catch (e) {
            toast.error('Ошибка', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } finally {
            // this.setLoadingDisciplineLeft(false)
        }
    }

    async saveToFinal(age_group_id, discipline_id) {
        try {
            const res = await Setting_sorevService.saveToFinal(age_group_id, discipline_id)
            if (res.data.status === "error") {
                toast.error(res.data.msg || 'Ошибка', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            } else {
                toast.success('Выполнено', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        } catch (e) {
            toast.error('Ошибка', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } finally {
        }
    }

    async reloadUsersData() {
        try {
            const res = await Setting_sorevService.reloadUsersData()
            if (res.data.status === "error") {
                toast.error(res.data.msg || 'Ошибка', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            } else {
                toast.success('Данные обновлены', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        } catch (e) {
            toast.error('Ошибка', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } finally {
        }
    }

    async uploadOrder() {
        try {
            const res = await Setting_sorevService.uploadOrder()
            if (res.data.status === "error") {
                toast.error(res.data.msg || 'Ошибка', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            } else {
                toast.success('Выполнено', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        } catch (e) {
            toast.error('Ошибка', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } finally {
        }
    }
}
