import api from "../../api/api";

class FigureService {
    async getCommand(id) {
        return await api().get(`figure/users/${id}`)
    }

    async getCommandByName(name, id) {
        return await api().get(`figure/users/${name}/${id}`)
    }

    async getRecalclulate(id) {
        return await api().get(`figure/recalculation/${id}`)
    }

    async getFigure(id) {
        return await api().get(`figure/figure_brigade/${id}`)
    }

    async setScore(score,id) {
        return await api().get(`figure/score/${score}/${id}`)
    }
}


export default new FigureService()
