import React, {useEffect, useState} from 'react';
import s from './styles.module.css'
import {observer} from "mobx-react-lite";
import {useStore} from "../../../../hooks/useStore";
import {toast} from "react-toastify";

const InputBox = observer(({value: old_value, id, index_box, user_id, score: isNan, updateInput}) => {
        const {figure} = useStore()

        const [score, setScore] = useState(old_value || '')

        const handlerSetScore = async (value) => {
            const res = await figure.setScore(value, id, index_box, user_id, old_value, setScore)
        }
        const numberInputOnWheelPreventChange = (e) => {
            e.target.blur()
        }

        useEffect(() => {
            setScore(old_value || '')
        }, [updateInput])
        return (
            <input step={0.01} value={score} onWheel={numberInputOnWheelPreventChange} onBlur={(e) => {
                handlerSetScore(e.target.value)
            }} onChange={(e) => setScore(e.target.value)} type={'number'} className={s.input_box}/>
        );
    })
;

export default InputBox;
