import React, {useEffect, useState} from 'react';
import s from './styles.module.css'
import Wrapper from "../../../components/wrapper";
import CommandItem from "./command_item";
import {IconButton} from "@mui/material";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import classNames from "classnames";

const Commands = ({
                      isReferee,
                      statusInfo,
                      setShowMoreCommand,
                      getScoreCommand,
                      showMoreCommand,
                      setSelectedCommand,
                      selectedCommand,
                      commands,
                      select,
                      socketData,
                      doubleStartJudging,
                  }) => {

    const handlerSelectCommand = (id) => {
        setSelectedCommand(id)
        getScoreCommand(id)
    }
    useEffect(() => {
        if (socketData?.method === 'update_scores') {

            if (selectedCommand === +socketData?.competition_application_discipline_id) {
                setSelectedCommand(+socketData?.competition_application_discipline_id)
                getScoreCommand(+socketData?.competition_application_discipline_id)
            } else if (selectedCommand === null) {
                return
            } else {
                setSelectedCommand(selectedCommand)
                getScoreCommand(selectedCommand)
            }
        }
        if (socketData?.method === 'update') {
            getScoreCommand(selectedCommand)
            setSelectedCommand(selectedCommand)
        }
    }, [socketData])

    return (
        <div className={s.command}>
            <Wrapper>
                {commands.length === 0 ? <div className={s.empty}>
                    {select ? 'Нет команд' : 'Выберите возрастную группу + дисциплину'}

                </div> : <div className={s.commands_box}>

                    <div className={classNames(s.show_hidden_brn, showMoreCommand && s.rotate)}
                         onClick={() => setShowMoreCommand(!showMoreCommand)}>
                        <IconButton aria-label="delete" sx={{
                            backgroundColor: '#fff'
                        }}>
                            <KeyboardDoubleArrowDownIcon/>
                        </IconButton>
                    </div>

                    <div className={classNames(s.commands_box_header)}>
                        <h3 className={s.command_name}>Команды</h3>
                        <h3 className={s.total_name}>Total Difficulty </h3>
                        <h3 className={s.elements_name}>Elements</h3>
                        <h3 className={s.artistic_name}>Artistic Impression</h3>
                        <h3 className={s.points_name}>Total Points</h3>
                        <h3 className={s.total_name}>Points Behind</h3>
                        <h3 className={s.rank_name}>Rank</h3>
                    </div>
                    <div className={classNames(s.commands_box_items, showMoreCommand && s.show_full)}>
                        {commands.map((el, index) => {
                            return <CommandItem doubleStartJudging={doubleStartJudging} poins_behind={el.poins_behind}
                                                rank={el.rank}
                                                isReferee={isReferee}
                                                command_name={el.competition_application_users}
                                                activeCommand={statusInfo?.competition_application_discipline_id}
                                                is_processing={el?.is_processing}
                                                key={el.competition_application_discipline_id}
                                                id={el.competition_application_discipline_id}
                                                selectedCommand={selectedCommand}
                                                handlerSelectCommand={handlerSelectCommand}
                                                index={index + 1} {...el}/>
                        })}
                    </div>
                </div>}
            </Wrapper>
        </div>
    );
};

export default Commands;
